import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import YAMLTncData from "../content/My-TNC-Content.yaml"
import ContactSection from "../components/contactSection"
import Divider from "../components/divider"

export default function TncPage() {

  return (
    <Layout>
      <Seo title="AGB" />

      <div className="relative shadow-xl sm:overflow-hidden">
        <div className="absolute inset-0">
          <StaticImage src="../images/blake-verdoorn-gM-RfQsZK98-unsplash.jpg"
                       alt="Caffeinity title image coffee beans"
                       transformOptions={{ grayscale: true }}

          />

        </div>
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            <span className="block">Allgemeine Geschäftsbedingungen</span>
          </h1>
        </div>
      </div>

      <div className="text-center text-caffeinity-green-dark my-16 max-w-5xl mx-auto">
        {YAMLTncData.content.map((data, index) => {
          return (
            <div key={`content_title_${index}`} className="mt-6">
              <ol >
                <li>
                  <h3 key={`content_title_${index}`}>{data.title}</h3>
                  <ol className="list-decimal">
                    {data.content.map((dataItem, indexItem) => {
                      return (
                        <li key={`content_item_${indexItem}`}>{dataItem.item}</li>
                      )
                    })}
                  </ol>
                </li>
              </ol>
            </div>
          )

        })}
        <div className="mt-8">
          <i className="font-light text-gray-600">Stand Dezember 2021</i>
        </div>

        <Divider />
        <ContactSection />

      </div>


    </Layout>
  )
}

